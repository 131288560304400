import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import BootcampAICurriculum from '../../components/BootcampAI/BootcampAICurriculum'
import BootcampAIVideo from '../../components/BootcampAI/BootcampAIVideo'
// import BootcampAISyllabus from '../../components/BootcampAI/BootcampAISyllabus'
import BootcampHeader from '../../components/Bootcamp/BootcampHeader/BootcampHeader'
import BootcampForm from '../../components/Bootcamp/BootcampForm/BootcampForm'
import BootcampContent from '../../components/Bootcamp/BootcampContent/BootcampContent'
import BootcampAIBenefits from '../../components/BootcampAI/BootcampAIBenefits'
import BootcampTestimonials from '../../components/Bootcamp/BootcampTestimonials/BootcampTestimonials'
import BootcampSummary from '../../components/Bootcamp/BootcampSummary/BootcampSummary'
import BootcampAIFAQ from '../../components/BootcampAI/BootcampAIFAQ'
import { trackMixpanel } from '../../mixpanelWrapper';

function AIBootcampPage() {
  useEffect(() => {
    trackMixpanel('BootcampAIVisit');
  }, []);

  return (
    <div>
        <NavBar/>
        <BootcampHeader />
        <BootcampForm senderTag='eZmkyv' />
        <BootcampContent />
        <BootcampAIBenefits />
        <BootcampAIVideo />
        {/* <BootcampAISyllabus /> */}
        <BootcampForm senderTag='eZmkyv' />
        <BootcampAICurriculum />
        <BootcampSummary />
        <BootcampTestimonials />
        <BootcampForm senderTag='eZmkyv' />
        <BootcampAIFAQ />
    </div>
  )
}

export default AIBootcampPage